import { isBrowser } from '@websolutespa/bom-core';
import { useClickOut, useLabel } from '@websolutespa/bom-mixer-hooks';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLlm } from '../../../useLlm/useLlm';

export const Disclaimer = () => {
  const label = useLabel();
  const disclaimerRef = useRef<HTMLDivElement>(null);
  const app = useLlm(state => state.app);
  const [active, setActive] = useState(false);
  const appDisclaimer = app?.contents.disclaimer;
  const appDisclaimerShort = app?.contents.disclaimerShort;
  const appDisclaimerFooter = app?.contents.disclaimerFooter;
  const disclaimer = appDisclaimerFooter ? (appDisclaimerShort || appDisclaimer || label('llm.disclaimer')) : '';
  const disclaimerLong = appDisclaimerFooter && active ? (appDisclaimer || label('llm.disclaimer')) : '';
  useEffect(() => {
    let buttons: HTMLButtonElement[] = [];
    if (disclaimerRef.current) {
      buttons = Array.from(disclaimerRef.current.querySelectorAll('button'));
    }
    const buttonClickHandler = async (event: MouseEvent) => {
      if (event.currentTarget && disclaimer !== disclaimerLong) {
        console.log('buttonClickHandler', disclaimerLong);
        setActive(true);
      }
    };
    buttons.forEach(x => x.addEventListener('click', buttonClickHandler));
    return () => {
      buttons.forEach(x => x.removeEventListener('click', buttonClickHandler));
    };
  }, [disclaimer, disclaimerLong]);
  useClickOut(disclaimerRef, () => {
    setActive(false);
  });
  return (
    <>
      {disclaimer && (
        <div className="llm__prompt-disclaimer">
          <div className="llm__prompt-disclaimer__short" ref={disclaimerRef} dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
          {isBrowser && disclaimerLong && createPortal(
            <div className="llm">
              <div className="llm__prompt-disclaimer__long" dangerouslySetInnerHTML={{ __html: disclaimerLong }}></div>
            </div>,
            document.body
          )}
        </div>
      )}
    </>
  );
};
