import {
  ICategory,
  IEntity, IEquatable, IPageRelation, IPageUnresolvedRelation, IUndecoratedMenuCategory,
  IUndecoratedMenuItem, IUndecoratedMenuPage, IUndecoratedNav
} from '../types';

/**
 * Checks if the given item is a number.
 * @param item The item to check.
 * @returns True if the item is a number, false otherwise.
 */
export function isNumber(item: unknown): item is number {
  return typeof item === 'number';
}

/**
 * Checks if the given item is a string.
 * @param item The item to check.
 * @returns True if the item is a string, false otherwise.
 */
export function isString(item: unknown): item is string {
  return typeof item === 'string';
}

/**
 * Checks if the given item is a valid non-empty string.
 * @param item The item to check.
 * @returns True if the item is a valid non-empty string, false otherwise.
 */
export function isValidString(item: unknown): item is string {
  return typeof item === 'string' && item.length > 0;
}

/**
 * Checks if the given item is an array.
 * @param item The item to check.
 * @returns True if the item is an array, false otherwise.
 */
export function isArray<T = unknown>(item: unknown): item is Array<T> {
  return Array.isArray(item);
}

/**
 * Checks if the given item is an object.
 * @param item The item to check.
 * @returns True if the item is an object, false otherwise.
 */
export function isObject<T = Record<string, unknown>>(item: unknown): item is T {
  return item != null && typeof item === 'object' && !isArray(item);
}

/**
 * Checks if the given item is a promise.
 * @param result The item to check.
 * @returns True if the item is a promise, false otherwise.
 */
export function isPromise(result: unknown): result is Promise<unknown> {
  return isObject(result) && typeof result.then === 'function';
}

/**
 * Checks if the given item is a non-null string.
 * @param item The item to check.
 * @returns True if the item is a non-null string, false otherwise.
 */
export function isNotNullString(item: unknown): item is string {
  return typeof item === 'string' && Boolean(item);
}

/**
 * Checks if the given item is equatable.
 * @param item The item to check.
 * @returns True if the item is equatable, false otherwise.
 */
export function isEquatable(item: unknown): item is IEquatable {
  return typeof item === 'number' || isNotNullString(item);
}

/**
 * Checks if the given item is an entity.
 * @param item The item to check.
 * @returns True if the item is an entity, false otherwise.
 */
export function isEntity<T = IEntity>(item: unknown): item is T {
  return isObject(item) && isEquatable(item.id);
}

/**
 * Checks if the given item is an unresolved relation.
 * @param item The item to check.
 * @returns True if the item is an unresolved relation, false otherwise.
 */
export function isUnresolvedRelation(item: unknown): item is IPageUnresolvedRelation {
  return isObject(item) && isNotNullString(item.relationTo) &&
    (isEquatable(item.value) || isArray(item.value));
}

/**
 * Checks if the given item is a relation.
 * @param item The item to check.
 * @returns True if the item is a relation, false otherwise.
 */
export function isRelation(item: unknown): item is IPageRelation {
  return isObject(item) && isNotNullString(item.relationTo) && isEntity(item.value);
}

export function isUnresolvedCategory(category?: IEquatable | ICategory): category is IEquatable {
  return category ? typeof category === 'string' : false;
}

export function isUndecoratedCategory(category: unknown): category is Omit<ICategory, 'href'> {
  return isObject(category) && !category.href;
}

/**
 * Checks if the given item is an undecorated menu item.
 * @param item The item to check.
 * @returns True if the item is an undecorated menu item, false otherwise.
 */
export function isUndecoratedMenuItem(item: unknown): item is IUndecoratedMenuItem {
  return isObject(item) && (
    (item.type === 'category' && isEquatable((item as IUndecoratedMenuCategory).category)) ||
    (item.type === 'page' && isEquatable((item as IUndecoratedMenuPage).page.value))
  );
}

/**
 * Checks if the given item is an undecorated menu category.
 * @param item The item to check.
 * @returns True if the item is an undecorated menu category, false otherwise.
 */
export function isUndecoratedMenuCategory(item: unknown): item is IUndecoratedMenuCategory {
  return isObject(item) &&
    item.type === 'category' &&
    isNotNullString((item as IUndecoratedMenuCategory).category) &&
    item.id === undefined &&
    item.href === undefined;
}

/**
 * Checks if the given item is an undecorated menu page.
 * @param item The item to check.
 * @returns True if the item is an undecorated menu page, false otherwise.
 */
export function isUndecoratedMenuPage(item: unknown): item is IUndecoratedMenuPage {
  return isObject(item) &&
    item.type === 'page' &&
    isObject(item.page) &&
    isNotNullString((item as IUndecoratedMenuPage).page.relationTo) &&
    isEquatable((item as IUndecoratedMenuPage).page.value);
}

/**
 * Checks if the given item is an undecorated navigation.
 * @param item The item to check.
 * @returns True if the item is an undecorated navigation, false otherwise.
 */
export function isUndecoratedNav(item: unknown): item is IUndecoratedNav {
  // return isObject(item) && isUndecoratedMenuPage(item.nav);
  return isUndecoratedMenuPage(item);
}
