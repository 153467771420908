import { getClassNames } from '@websolutespa/bom-core';
import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmArrowRight } from '@websolutespa/bom-mixer-icons';
import { LlmChunkAction, LlmChunkActionItem } from '../../../types';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { Cta } from '../Cta/cta';

export const Action = (props: LlmChunkActionItem | LlmChunkAction) => {
  const label = useLabel();
  const { onAction } = useLlm(state => state.actions);
  const { open } = useLlmView(state => state.actions);
  const dismissable = useLlmView(state => state.dismissable);
  const onAction_ = async (event: React.MouseEvent) => {
    // console.log('Action.onAction', props.id);
    const shouldClose = await onAction(props);
    if (dismissable && shouldClose !== false) {
      open();
    }
  };
  const classNames = getClassNames('llm__action', { [`llm__action--${props.type}`]: !!props.type });
  return (
    <Cta
      type="button"
      className={classNames} label={props.title || label('llm.tellMeMore')}
      icon={<IconLlmArrowRight />}
      onClick={onAction_}
    />
  );
};

