import { getClassNames } from '@websolutespa/bom-core';
import { LlmChunkActionGroup } from '../../../types';
import { Action } from '../Action/action';

export const ActionGroup = (props: LlmChunkActionGroup) => {
  const classNames = getClassNames('llm__actions');
  return (
    <div className={classNames}>
      {props.items.map((item, i) => (
        <Action key={i} {...item} />
      ))}
    </div>
  );
};

