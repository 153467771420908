// app css
import './app.scss';
// llm css
import '@websolutespa/bom-mixer-llm/src/llm/scss/style.scss';
// imports
import { ILabel, ILayout, IPage, isBrowser, localizeItem } from '@websolutespa/bom-core';
import { ExtraProvider, LabelProvider, LayoutProvider, PageProvider } from '@websolutespa/bom-mixer-hooks';
import { LlmComponent, LlmInstance, LlmProvider } from '@websolutespa/bom-mixer-llm';
import { useEffect, useMemo, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from './error-handler';
import label from './label.json';
import { LlmOptions } from './types';

export const App = ({ locale, market, instance, ...props }: LlmOptions & { instance?: LlmInstance }) => {

  const llmRef = useRef<LlmInstance>(null);

  const data = useMemo<PageData>(() => {
    if (isBrowser) {
      const htmlLang = getHtmlLang();
      const title = document.querySelector('title');
      const description = document.querySelector('meta[name="description"]');
      const canonical = document.querySelector('link[rel="canonical"]');
      return {
        locale: locale || htmlLang?.locale || 'it',
        market: market || htmlLang?.market || 'ww',
        origin: window.origin,
        canonical: canonical?.getAttribute('href') || window.location.href,
        title: title ? title.innerText : 'Untitled',
        description: description?.getAttribute('content') || '',
        slug: window.location.pathname,
      };
    } else {
      return {
        locale: locale || 'it',
        market: market || 'ww',
        origin: '',
        canonical: '',
        title: 'Untitled',
        description: '',
        slug: '',
      };
    }
  }, [locale, market]);
  const defaultEndpoint: string = typeof props.test !== 'undefined' ?
    'https://bom-sample-basic-web-git-bom-llm-actarian.vercel.app' :
    'https://platform.websolute.ai';
  const endpoint: string = props.endpoint || defaultEndpoint;
  const layout: ILayout = {
    labels: getLabels(data.locale) as ILabel[],
    locale: data.locale,
    locales: [{
      id: data.locale, title: data.locale,
    }],
    market: data.market,
    markets: [{
      id: data.market, title: data.market,
    }],
    menu: {},
    topLevelHrefs: {},
    topLevelRoutes: {},
  };
  const page: IPage = {
    locale: data.locale,
    market: data.market,
    title: data.title,
    abstract: data.description,
    category: {
      id: 'homepage',
      title: 'Homepage',
      slug: '',
      href: '/',
    },
    schema: 'homepage',
    slug: data.slug,
    href: data.canonical,
    id: 1,
    alternates: [],
    breadcrumb: [],
    canonical: data.canonical,
  };
  const extra = {};

  useEffect(() => {
    if (llmRef.current && instance) {
      // console.log('App', llmRef.current, instance);
      Object.assign(instance, llmRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <LayoutProvider layout={layout}>
        <LabelProvider>
          <PageProvider page={page}>
            <ExtraProvider extra={extra}>
              <LlmProvider {...props as React.ComponentProps<typeof LlmProvider>} endpoint={endpoint} >
                <LlmComponent ref={llmRef} />
              </LlmProvider>
            </ExtraProvider>
          </PageProvider>
        </LabelProvider>
      </LayoutProvider>
    </ErrorBoundary>
  );
};

function getLabels(locale: string) {
  return label.map(x => {
    const localizedItem = localizeItem(x, locale, 'en');
    return {
      id: localizedItem.id,
      schema: 'label',
      text: localizedItem.text,
    };
  });
}

function getHtmlLang(): { locale: string, market: string } | undefined {
  const html = document.querySelector('html');
  const htmlLang = html?.getAttribute('lang');
  if (htmlLang) {
    const segments = htmlLang.toLowerCase().split('-');
    return {
      locale: segments[0],
      market: segments[1] || 'ww',
    };
  } else {
    return undefined;
  }
}

type PageData = {
  locale: string;
  market: string;
  origin: string;
  canonical: string;
  title: string;
  description: string;
  slug: string;
};
