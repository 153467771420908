/* eslint-disable comma-dangle */
/* eslint-disable quotes */

import { LlmMockApp } from "../types";

export const mockApp: LlmMockApp = {
  "it": {
    "mode": "site",
    "contents": {
      "logo": {
        "id": "6560a04654a910c2150ab7a9",
        "alt": "logo Pesaro2024",
        "filename": "logo-pesaro-2025.svg",
        "mimeType": "image/svg+xml",
        "filesize": 28279,
        "width": 198,
        "height": 60,
        "sizes": {
          "card": {},
          "feature": {},
        },
        "createdAt": "2023-11-24T13:08:22.746Z",
        "updatedAt": "2023-11-24T13:08:22.746Z",
        "url": "https://design.wslabs.it/llm/media/logo-pesaro-2024.svg",
        "src": "https://design.wslabs.it/llm/media/logo-pesaro-2024.svg",
        "type": "image",
      },
      "logoMini": {
        "id": "6560a04654a910c2150ab7a9",
        "alt": "logo Pesaro2024",
        "filename": "logo-pesaro-2025.svg",
        "mimeType": "image/svg+xml",
        "filesize": 28279,
        "width": 198,
        "height": 60,
        "sizes": {
          "card": {},
          "feature": {},
        },
        "createdAt": "2023-11-24T13:08:22.746Z",
        "updatedAt": "2023-11-24T13:08:22.746Z",
        "url": "https://design.wslabs.it/llm/media/logo-pesaro-2024-mini.svg",
        "src": "https://design.wslabs.it/llm/media/logo-pesaro-2024-mini.svg",
        "type": "image",
      },
      "collapsedWelcomeText": "👋 \n Ciao, sono il tuo assistente AI",
      "collapsedWelcomeTextHover": "Cliccami e lascia che ti consigli cosa fare e vedere a Pesaro 2024.",
      "shortWelcomeText": "Ciao, sono l\"ambasciatore AI di Pesaro2024.",
      "extendedWelcomeText": "Ciao, sono l\"Ambasciatore AI di Pesaro2024. Più cose mi dirai su ciò che ti piace, più sarò in grado di aiutarti.",
      "disclaimer": "Le risposte che riceverai sono create automaticamente elaborando sia le informazioni presenti sul nostro sito che altre informazioni presenti sul web. Controlla sempre la correttezza delle informazioni fornite.",
      "sampleInputTexts": [
        {
          "sampleInputText": "Quali sono gli eventi che posso raggiungere a piedi dalla stazione dei treni?"
        },
        {
          "sampleInputText": "Mostra i prossimi eventi di fotografia"
        },
        {
          "sampleInputText": "Chi partecipa a Pesaro2024?"
        },
      ],
      "layoutBuilder": [
        {
          "icon": {
            "id": "6560a05454a910c2150ab7b4",
            "alt": "itinerario",
            "filename": "itinerary-1.svg",
            "mimeType": "image/svg+xml",
            "filesize": 1174,
            "width": 32,
            "height": 33,
            "sizes": {
              "card": {},
              "feature": {},
            },
            "createdAt": "2023-11-24T13:08:36.444Z",
            "updatedAt": "2023-11-24T13:08:36.444Z",
            "url": "https://design.wslabs.it/llm/media/llm-itinerary.svg",
            "src": "https://design.wslabs.it/llm/media/llm-itinerary.svg",
            "type": "image",
          },
          "title": "Pianifica un itinerario",
          "body": "Chiedi supporto per organizzare il tuo weekend o il tuo soggiorno a Pesaro godendo di tutti gli eventi di Pesaro2024.",
          "id": "656095900d5d91982799c400",
          "blockType": "card",
        },
        {
          "icon": {
            "id": "6560a06954a910c2150ab7c9",
            "alt": "pin",
            "filename": "pin-1.svg",
            "mimeType": "image/svg+xml",
            "filesize": 1300,
            "width": 32,
            "height": 32,
            "sizes": {
              "card": {},
              "feature": {},
            },
            "createdAt": "2023-11-24T13:08:57.714Z",
            "updatedAt": "2023-11-24T13:08:57.714Z",
            "url": "https://design.wslabs.it/llm/media/llm-pin.svg",
            "src": "https://design.wslabs.it/llm/media/llm-pin.svg",
            "type": "image",
          },
          "title": "Esplora Pesaro",
          "body": "Chiedi informazioni sui luoghi di cultura della Città che hanno valso a Pesaro il titolo di Capitale Italiana della Cultura 2024.",
          "id": "65609fe4152d35137e46286d",
          "blockType": "card",
        },
        {
          "icon": {
            "id": "6560a07454a910c2150ab7d3",
            "alt": "cerca",
            "filename": "search.svg",
            "mimeType": "image/svg+xml",
            "filesize": 680,
            "width": 32,
            "height": 32,
            "sizes": {
              "card": {},
              "feature": {},
            },
            "createdAt": "2023-11-24T13:09:08.678Z",
            "updatedAt": "2023-11-24T13:09:08.678Z",
            "url": "https://design.wslabs.it/llm/media/llm-search.svg",
            "src": "https://design.wslabs.it/llm/media/llm-search.svg",
            "type": "image",
          },
          "title": "Scopri il progetto",
          "body": "Chiedi dettagli su come è nato il progetto Pesaro2024, come funziona e quali sono le tante realtà virtuose del territorio coinvolte.",
          "id": "65609fe8152d35137e46286e",
          "blockType": "card",
        },
      ],
    },
  },
  "en": {
    "mode": "site",
    "contents": {
      "logo": {
        "id": "6560a04654a910c2150ab7a9",
        "alt": "logo Pesaro2024",
        "filename": "logo-pesaro-2025.svg",
        "mimeType": "image/svg+xml",
        "filesize": 28279,
        "width": 198,
        "height": 60,
        "sizes": {
          "card": {},
          "feature": {},
        },
        "createdAt": "2023-11-24T13:08:22.746Z",
        "updatedAt": "2023-11-24T13:08:22.746Z",
        "url": "https://design.wslabs.it/llm/media/logo-pesaro-2024.svg",
        "src": "https://design.wslabs.it/llm/media/logo-pesaro-2024.svg",
        "type": "image",
      },
      "logoMini": {
        "id": "6560a04654a910c2150ab7a9",
        "alt": "logo Pesaro2024",
        "filename": "logo-pesaro-2025.svg",
        "mimeType": "image/svg+xml",
        "filesize": 28279,
        "width": 198,
        "height": 60,
        "sizes": {
          "card": {},
          "feature": {},
        },
        "createdAt": "2023-11-24T13:08:22.746Z",
        "updatedAt": "2023-11-24T13:08:22.746Z",
        "url": "https://design.wslabs.it/llm/media/logo-pesaro-2024-mini.svg",
        "src": "https://design.wslabs.it/llm/media/logo-pesaro-2024-mini.svg",
        "type": "image",
      },
      "collapsedWelcomeText": "👋 \n Hi, I'm your AI assistant",
      "collapsedWelcomeTextHover": "Click me and let me advise you on what to do and see in Pesaro 2024.",
      "shortWelcomeText": "Hi, I'm the AI ​​ambassador of Pesaro2024.",
      "extendedWelcomeText": "Hi, I'm the AI ​​Ambassador of Pesaro2024. The more you tell me about what you like, the more I will be able to help you.",
      "disclaimer": "The answers you will receive are created automatically by processing both the information on our site and other information on the web. Always check the correctness of the information provided.",
      "sampleInputTexts": [
        {
          "sampleInputText": "What events can I walk to from the train station?"
        },
        {
          "sampleInputText": "Shows upcoming photography events"
        },
        {
          "sampleInputText": "Who participates in Pesaro2024?"
        },
      ],
      "layoutBuilder": [
        {
          "icon": {
            "id": "6560a05454a910c2150ab7b4",
            "alt": "itinerario",
            "filename": "itinerary-1.svg",
            "mimeType": "image/svg+xml",
            "filesize": 1174,
            "width": 32,
            "height": 33,
            "sizes": {
              "card": {},
              "feature": {},
            },
            "createdAt": "2023-11-24T13:08:36.444Z",
            "updatedAt": "2023-11-24T13:08:36.444Z",
            "url": "https://design.wslabs.it/llm/media/llm-itinerary.svg",
            "src": "https://design.wslabs.it/llm/media/llm-itinerary.svg",
            "type": "image",
          },
          "title": "Plan an itinerary",
          "body": "Ask for support to organize your weekend or your stay in Pesaro enjoying all the events of Pesaro2024.",
          "id": "656095900d5d91982799c400",
          "blockType": "card",
        },
        {
          "icon": {
            "id": "6560a06954a910c2150ab7c9",
            "alt": "pin",
            "filename": "pin-1.svg",
            "mimeType": "image/svg+xml",
            "filesize": 1300,
            "width": 32,
            "height": 32,
            "sizes": {
              "card": {},
              "feature": {},
            },
            "createdAt": "2023-11-24T13:08:57.714Z",
            "updatedAt": "2023-11-24T13:08:57.714Z",
            "url": "https://design.wslabs.it/llm/media/llm-pin.svg",
            "src": "https://design.wslabs.it/llm/media/llm-pin.svg",
            "type": "image",
          },
          "title": "Explore Pesaro",
          "body": "Ask for information on the cultural places of the city that earned Pesaro the title of Italian Capital of Culture 2024.",
          "id": "65609fe4152d35137e46286d",
          "blockType": "card",
        },
        {
          "icon": {
            "id": "6560a07454a910c2150ab7d3",
            "alt": "cerca",
            "filename": "search.svg",
            "mimeType": "image/svg+xml",
            "filesize": 680,
            "width": 32,
            "height": 32,
            "sizes": {
              "card": {},
              "feature": {},
            },
            "createdAt": "2023-11-24T13:09:08.678Z",
            "updatedAt": "2023-11-24T13:09:08.678Z",
            "url": "https://design.wslabs.it/llm/media/llm-search.svg",
            "src": "https://design.wslabs.it/llm/media/llm-search.svg",
            "type": "image",
          },
          "title": "Discover the project",
          "body": "Ask for details on how the Pesaro2024 project was born, how it works and which are the many virtuous local realities involved.",
          "id": "65609fe8152d35137e46286e",
          "blockType": "card",
        },
      ],
    },
  },
};
