import { useLabel } from '@websolutespa/bom-mixer-hooks';
import { IconLlmArrowRight } from '@websolutespa/bom-mixer-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useLlmView } from '../../../useLlm/useLlmView';

const slideIn = (direction: number = 1, percent: number = 30) => ({
  hidden: {
    x: '-50%',
    y: `${percent * direction}%`,
    opacity: 0,
  },
  show: {
    x: '-50%',
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      duration: .3,
    },
  },
  exit: {
    y: `${percent * direction}%`,
    opacity: 0,
    transition: {
      type: 'tween',
      duration: .2,
    },
  },
});

export function ScrollProposition() {
  const label = useLabel();
  // const hasChunks = useLlm(state => state.chunks != null);
  const scrollable = useLlmView(state => state.scrollable);
  const { scrollToBottom } = useLlmView(state => state.actions);
  const onClick = () => {
    scrollToBottom();
  };
  return (
    <AnimatePresence>
      {scrollable && (
        <motion.button
          className="llm__scroll-proposition"
          aria-label={label('llm.scrollToBottom')} onClick={onClick}
          variants={slideIn(-1)}
          initial="hidden"
          animate="show"
          exit="exit">
          <IconLlmArrowRight />
        </motion.button>
      )}
    </AnimatePresence>
  );
}
